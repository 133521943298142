$(document).ajaxStart(function () {
    Pace.restart();
});

$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    moment.locale('de');

    $.fn.dataTable.moment('DD.MM.YYYY');
    $.fn.dataTable.moment('DD.MM.YYYY - HH:mm');


    $.fn.datepicker.defaults.autoclose = true;
    $.fn.datepicker.defaults.clearBtn = true;
    $.fn.datepicker.defaults.calendarWeeks = true;
    $.fn.datepicker.defaults.daysOfWeekHighlighted = '06';
    $.fn.datepicker.defaults.language = $('html').attr('lang');
    $.fn.datepicker.defaults.format = 'dd.mm.yyyy';
    $.fn.datepicker.defaults.todayBtn = true;
    $.fn.datepicker.defaults.todayHighlight = true;
    $.fn.datepicker.defaults.weekStart = 1;
    $.fn.datepicker.defaults.orientation = 'bottom';

    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: '1000',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
    };


    bootbox.addLocale('de-custom', {
        OK: 'Ok',
        CANCEL: 'Abbrechen',
        CONFIRM: 'Bestätigen'
    });
    bootbox.setDefaults({
        locale: 'de-custom',
    });


    if ($('.auswertung_export_excel').length > 0) {
        $('.auswertung_export_excel').on('click', function (e) {
            e.preventDefault();

            var myform = $(this).parents('form');

            myform.find('button[name=export][value=excel]').click();
        });
    }

    if ($('.range_select_input').length > 0) {
        initSelectRange();
    }

    if ($('.search_user_select').length > 0) {
        initSelectUser();
    }


    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });

    $('.scroll-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });


    var notifications_menu = $('.notifications-menu');

    if (notifications_menu.length > 0) {
        setInterval(function () {
            $.get(notifications_menu.data('check-url'), {}, function (data, textStatus, jqXHR) {
                notifications_menu.find('ul.menu').html('');

                if (data.length > 0) {
                    notifications_menu.find('.label.count').html(data.length);
                    notifications_menu.find('.notification_count').html(data.length);
                } else {
                    notifications_menu.find('.label.count').html('');
                    notifications_menu.find('.notification_count').html('Keine');
                }

                if (data.length > 0) {
                    var insert_container = notifications_menu.find('ul.menu');

                    jQuery.each(data.reverse(), function (index, element) {
                        insert_container.prepend(element)
                    });
                }
            }, 'json');
        }, 3e5);
    }
});

/**
 * Display message alert.
 *
 * @param string title
 * @param sring text
 */
function displayMessage(type, title, text) {
    switch (type) {
        case 'error':
            toastr.error(text, title);
            break;
        case 'success':
            toastr.success(text, title);
            break;
        case 'info':
        default:
            toastr.info(text, title);
            break;
    }
}

/**
 * Display success message (wrapper).
 *
 * @param string text
 */
function displaySuccess(text) {
    displayMessage('success', 'Erledigt', text);
}

/**
 * Display error message (wrapper).
 *
 * @param string text
 */
function displayError(text) {
    displayMessage('error', 'Fehler', text);
}

/**
 * Init DataTable.
 *
 * @param {jQuery} table_element
 * @returns {jQuery}
 */
function initDataTable(table_element, custom_options) {
    var options = $.extend({
        language: {
            "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
            "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
            "sInfoEmpty": "0 bis 0 von 0 Einträgen",
            "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "_MENU_ Einträge anzeigen",
            "sLoadingRecords": "Wird geladen...",
            "sProcessing": "Bitte warten...",
            "sSearch": "Suchen",
            "sZeroRecords": "Keine Einträge vorhanden.",
            "oPaginate": {
                "sFirst": "Erste",
                "sPrevious": "Zurück",
                "sNext": "Nächste",
                "sLast": "Letzte"
            },
            "oAria": {
                "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
                "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren",
            }
        },
        "bSortCellsTop": true,
        "order": [[1, 'asc']],
        "columnDefs": [{
            "targets": 'no-sort',
            "orderable": false,
        }],
    }, custom_options)

    var dtTable = table_element.DataTable(options); // use DataTable, not dataTable

    return dtTable;
}

function submitMonthForm() {
    var selectedOption = $('input[name=month].select_month').val();
    var url = "http://" + window.location.hostname + window.location.pathname + '?month=' + e.format();

    $('#myForm').attr('action', url);
    $('form#myForm').submit();
    return false;
}

function submitYearForm() {
    var selectedOption = $('select[name=year].select_year').val();
    var url = "http://" + window.location.hostname + window.location.pathname + '?year=' + $(this).val();

    $('#myForm').attr('action', url);
    $('form#myForm').submit();
    return false;
}

/**
 * Init select range.
 */
function initSelectRange() {
    $('.range_select_input').daterangepicker({
        locale: {
            format: 'DD.MM.YYYY',
            applyLabel: 'Auswählen',
            cancelLabel: 'Abbrechen',
            monthNames: moment.months(),
        },
    });
}

/**
 * Init select User.
 */
function initSelectUser() {
    $('.search_user_select').select2({
        placeholder: 'Mitarbeiter suchen...',
        allowClear: true,
        ajax: {
            url: $('.search_user_select').data('search-url'),
            method: 'POST',
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term,
                };
            },
            processResults: function (data) {
                return {
                    results: data,
                };
            },
            cache: true,
        },
        minimumInputLength: 3,
        templateResult: function (userdata) {
            if (userdata.loading) {
                return userdata.text;
            }

            return userdata.name;
        },
        templateSelection: function (userdata) {
            return userdata.name || userdata.text;
        },
    });
}

/**
 * Init art guesser.
 *
 * @param string myurl
 */
function initArtGuesser(myurl) {
    user_has_clicked_art_radio = false;

    $('input[type="radio"][name="art"]').on('click', function () {
        user_has_clicked_art_radio = true;
    });

    var elem = $('input[name="datum"]');
    var mydate = elem.val();

    if (user_has_clicked_art_radio == false) {
        getArtGuess(myurl, mydate);
    }

    elem.on('changeDate', function (e) {
        if (mydate != e.format() && user_has_clicked_art_radio == false) {
            mydate = e.format();

            getArtGuess(myurl, mydate);
        }
    });
}

/**
 * Get art guess.
 *
 * @param string myurl
 * @param string mydate
 */
function getArtGuess(myurl, mydate) {
    var mylabel = $('.art-label-indicator');
    var mylabelhtml = mylabel.html();

    mylabel.html(mylabelhtml + ' <i class="fa fa-spin fa-spinner"></i>');

    var myajaxdata = {
        datum: mydate,
    };

    var search_user = $('select[name="search-user"]').val();

    if (search_user) {
        myajaxdata['search-user'] = search_user;
    }

    $.ajax({
        url: myurl,
        method: 'POST',
        data: myajaxdata,
        dataType: 'json',
        error: function (jqXHR, textStatus, errorThrown) {
            //
        },
        success: function (data, textStatus, jqXHR) {
            if (data.art && user_has_clicked_art_radio == false) {
                $('input[name="art"][value="' + data.art + '"]').prop('checked', true); // does not trigger click event
            }
        },
        complete: function (jqXHR, textStatus) {
            mylabel.html(mylabelhtml);
        },
    })
}

/**
 * Init MaximalPluszeit.
 *
 * @param string myurl
 */
function initMaximalPluszeit(myurl) {
    $('#maximalpluszeit_form select[name="typ"]').on('change', function () {
        getMaximalPluszeitInfo($(this).parents('form'), myurl);
    });

    $('#maximalpluszeit_form input[name="datum"]').on('change', function () {
        getMaximalPluszeitInfo($(this).parents('form'), myurl);
    });

    getMaximalPluszeitInfo($('#maximalpluszeit_form'), myurl);

    $('#maximalpluszeit_info').on('click', 'button.btn-max-zeit', function (e) {
        e.preventDefault();

        var zeit = $(this).data('max-zeit');

        $('#maximalpluszeit_form').find('input[name="zeit"]').val(zeit);
    })
}

/**
 * Fetch MaximalPluszeit information.
 *
 * @param {jQuery} form
 * @param string myurl
 */
function getMaximalPluszeitInfo(form, myurl) {
    var mytyp = form.find('select[name="typ"]').val();
    var mydatum = form.find('input[name="datum"]').val();
    var myuserid = form.find('input[name="userid"]').val();

    if (!mydatum || !mytyp || !myuserid) {
        return;
    }

    var container = $('#maximalpluszeit_info');

    container.spin();

    $.ajax({
        url: myurl,
        method: 'POST',
        dataType: 'html',
        data: {
            typ: mytyp,
            datum: mydatum,
            userid: myuserid,
        },
        error: function () {
            container.html('<div class="alert alert-danger">Fehler beim Laden der Informationen. Bitte prüfen Sie die Konfiguration für das gewählte Jahr.</div>');
        },
        success: function (data) {
            container.html(data);
        },
        complete: function () {
            container.spin(false);
        },
    });
}
